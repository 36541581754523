import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Spinner,
  useToast,
  Box,
  Heading,
  // IconButton,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

const Candidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const token = localStorage.getItem("token");
  const UUID = localStorage.getItem("UUID");
  const HuserID = localStorage.getItem("HuserID");

  const API_BASE_URL = "https://qaapirgeneng.digitalfields.co.za/ws1.cfc";

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}?method=listcandidates&huserID=${HuserID}&uuid=${UUID}`,
          {
            method: "POST",
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setCandidates(data);
      } catch (error) {
        console.error("Error fetching candidates:", error);
        toast({
          title: "Error fetching candidates",
          description:
            "There was an error fetching the candidates. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCandidates();
  }, [toast, token]);

  const handleViewEdit = (candidate) => {
    navigate(`/candidate/${candidate.CandidateID}`, {
      state: { scrollPosition: tableRef.current?.scrollTop },
    });
  };

  useEffect(() => {
    if (tableRef.current && window.history.state?.scrollPosition) {
      tableRef.current.scrollTop = window.history.state.scrollPosition;
    }
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box p={5}>
      <Heading mb={5}>Candidate List</Heading>
      <Button
        variant="outline-rounded"
        leftIcon={<AddIcon />}
        onClick={() => navigate("/register")}
        mb={5}
      >
        Add Candidate
      </Button>
      <Box ref={tableRef} overflowY="auto" maxHeight="70vh">
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Email</Th>
              <Th>Active</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {candidates.map((candidate) => (
              <Tr key={candidate.CandidateID}>
                <Td>{candidate.CandidateID}</Td>
                <Td>{candidate.fname}</Td>
                <Td>{candidate.sname}</Td>
                <Td>{candidate.email}</Td>
                <Td>{candidate.activeYN ? "Yes" : "No"}</Td>
                <Td>
                  <Button
                    variant="outline-rounded"
                    onClick={() => handleViewEdit(candidate)}
                  >
                    View/Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Candidates;
