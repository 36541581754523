import React from "react";
import ChakraWrapper from "./styles/ChakraWrapper";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Signin from "./pages/login/Signin";
import Home from "./pages/home/Home";
import Candidates from "./pages/candidates/Candidates";
import Candidate from "./pages/candidates/Candidate";
import Register from "./pages/candidates/Register";
import Profile from "./pages/profile/Profile";
import Navbar from "./components/Navbar";

function App() {
  const location = useLocation();
  const shouldShowNavbar = location.pathname !== "/";

  return (
    <ChakraWrapper>
      {shouldShowNavbar && <Navbar />}
      <Routes>
        <Route
          path="/"
          element={
            <Signin
              onLoginSuccess={() => (window.location.href = "/dashboard")}
            />
          }
        />
        <Route path="/dashboard" element={<Home />} />
        <Route path="/candidates" element={<Candidates />} />
        <Route path="/candidate/:id" element={<Candidate />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </ChakraWrapper>
  );
}

export default App;
