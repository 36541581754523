import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Button,
  IconButton,
  Input,
  Spinner,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  HStack,
  List,
  ListItem,
  Flex,
  Select,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import {
  CheckCircleIcon,
  CloseIcon,
  DownloadIcon,
  AddIcon,
  Icon,
} from "@chakra-ui/icons";
import { FaFilePdf, FaFileExcel, FaFileWord, FaFile } from "react-icons/fa";

const API_BASE_URL = "https://qaapirgeneng.digitalfields.co.za/ws1.cfc";
const GATEWAY_API_URL =
  "https://digitalfields.co.za/Gateway/rgeneng/qa/ws1.cfc";

const getIcon = (sourceDataType) => {
  switch (sourceDataType) {
    case "PDF":
      return FaFilePdf;
    case "DOC":
    case "DOCX":
      return FaFileWord;
    case "XLS":
    case "XLSX":
      return FaFileExcel;
    default:
      return FaFile;
  }
};

const Candidate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [candidate, setCandidate] = useState(null);
  const [previousReports, setPreviousReports] = useState(null);
  const [reportUploadOptions, setReportUploadOptions] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState("");
  const [draftReports, setDraftReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reportsLoading, setReportsLoading] = useState(true);
  const toast = useToast();
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [fileUploading, setFileUploading] = useState({
    xls: false,
    pdf: false,
  });
  const fileXLSRef = useRef(null);
  const filePDFRef = useRef(null);

  const HuserID = localStorage.getItem("HuserID");
  const UUID = localStorage.getItem("UUID");
  const HclientID = "1"; // This will need to become dynamic eventually but I've hardcoded for now

  const fetchData = useCallback(
    async (url, errorMessage) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      } catch (error) {
        console.error(errorMessage, error);
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        throw error;
      }
    },
    [toast]
  );

  // Added fetch report upload options
  useEffect(() => {
    const fetchReportOptions = async () => {
      try {
        const options = await fetchData(
          `${GATEWAY_API_URL}?method=reportUploadOptions&HuserID=${HuserID}&UUID=${UUID}&HclientID=${HclientID}`,
          "Error fetching report upload options"
        );
        setReportUploadOptions(options);
        if (options.length > 0) {
          setSelectedReportType(options[0].uploadMethod);
        }
      } catch (error) {
        // Error already handled in fetchData
      }
    };

    fetchReportOptions();
  }, [HuserID, UUID, fetchData]);

  useEffect(() => {
    const fetchCandidateDetails = async () => {
      try {
        const candidateData = await fetchData(
          `${API_BASE_URL}?method=getcandidateDets&huserID=${HuserID}&candidateID=${id}&UUID=${UUID}`,
          "Error fetching candidate details"
        );
        setCandidate(candidateData[0]);
        console.log(candidateData);

        const candidateReports = await fetchData(
          `${API_BASE_URL}?method=getExistingReportsForCandidate&huserID=${HuserID}&candidateID=${id}&UUID=${UUID}`,
          "Error fetching candidate details"
        );
        console.log(candidateReports);

        setPreviousReports(candidateReports);
      } catch (error) {
        // Error already handled in fetchData
      } finally {
        setLoading(false);
      }
    };

    fetchCandidateDetails();
  }, [id, HuserID, UUID, fetchData]);

  useEffect(() => {
    const fetchDraftReports = async () => {
      try {
        const reportsData = await fetchData(
          `${API_BASE_URL}?method=getReportsForCandidate&huserID=${HuserID}&candidateID=${id}&UUID=${UUID}`,
          "Error fetching draft reports"
        );
        setDraftReports(reportsData);
      } catch (error) {
        // Error already handled in fetchData
      } finally {
        setReportsLoading(false);
      }
    };

    fetchDraftReports();
  }, [id, HuserID, UUID, fetchData]);

  const handleBack = useCallback(() => {
    navigate("/candidates", {
      state: { scrollPosition: location.state?.scrollPosition },
    });
  }, [navigate, location.state?.scrollPosition]);

  const handleFileUpload = useCallback(
    async (file, fileType) => {
      if (!file) {
        toast({
          title: "No file selected",
          description: "Please select a file to upload.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      if (fileType === "pdf" && !selectedReportType) {
        toast({
          title: "No report type selected",
          description: "Please select a report type before uploading.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      setFileUploading((prev) => ({ ...prev, [fileType]: true }));

      const formData = new FormData();
      formData.append("uploadfile", file);

      const uploadUrl =
        fileType === "xls"
          ? `${API_BASE_URL}?method=uploadScoresSheet&huserID=${HuserID}&UUID=${UUID}&hclientID=${HclientID}&CandidateID=${id}`
          : `${API_BASE_URL}?method=${selectedReportType}&huserID=${HuserID}&hclientID=${HclientID}&CandidateID=${id}&UUID=${UUID}`;

      try {
        const response = await fetch(uploadUrl, {
          method: "POST",
          body: formData,
        });
        if (!response.ok) {
          const errorDetails = await response.json();
          throw new Error(
            `Network response was not ok, ${errorDetails.message} (Status: ${response.status})`
          );
        }

        toast({
          title: "Upload successful",
          description: `The ${
            fileType === "xls" ? "spreadsheet" : "report"
          } has been uploaded successfully.`,
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        // Reset file input
        if (fileType === "xls") fileXLSRef.current.value = "";
        if (fileType === "pdf") filePDFRef.current.value = "";
      } catch (error) {
        console.error("Upload failed:", error);
        toast({
          title: "Upload failed",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setFileUploading((prev) => ({ ...prev, [fileType]: false }));
      }
    },
    [HuserID, UUID, id, toast, selectedReportType, HclientID]
  );

  const handleUploadSpreadsheet = useCallback(() => {
    const file = fileXLSRef.current.files[0];
    handleFileUpload(file, "xls");
  }, [handleFileUpload]);

  const handleUploadReportPdf = useCallback(() => {
    const file = filePDFRef.current.files[0];
    handleFileUpload(file, "pdf");
  }, [handleFileUpload]);

  const handleDownload = useCallback(
    async (hreportID, index) => {
      setLoadingIndex(index);
      try {
        const url = `https://qaapirgeneng.digitalfields.co.za/Aiscratch/reportpreview1.cfm?candidateid=${id}&hreportID=${hreportID}&UUID=${UUID}`;
        window.open(url, "_blank");
      } catch (error) {
        console.error("Error downloading the report", error);
        toast({
          title: "Download failed",
          description: "There was an error downloading the report.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setLoadingIndex(null);
    },
    [id, UUID, toast]
  );

  if (loading || reportsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!candidate) {
    return (
      <Box p={5}>
        <Button onClick={handleBack} mb={5}>
          Back to Candidates
        </Button>
        <Heading mb={5}>Candidate Details</Heading>
        <Text>No candidate data available.</Text>
      </Box>
    );
  }

  let { candidateDets, assessments } = candidate;
  candidateDets = candidateDets[0];

  return (
    <Box p={5}>
      <Button onClick={handleBack} mb={5}>
        Back to Candidates
      </Button>
      <Heading mb={5}>Candidate Details</Heading>
      <VStack align="start" spacing={4} mb={8}>
        <Text>
          <strong>Name:</strong> {candidateDets.fname} {candidateDets.sname}
        </Text>
        <Text>
          <strong>Email:</strong> {candidateDets.email}
        </Text>
        <Text>
          <strong>Employee Number:</strong> {candidateDets.employeeNo || "N/A"}
        </Text>
        <Text>
          <strong>Active:</strong> {candidateDets.activeYN ? "Yes" : "No"}
        </Text>
      </VStack>

      <Heading size="md" mb={4}>
        Assessments
      </Heading>
      {assessments && assessments.length > 0 ? (
        <Table variant="striped" mb={8}>
          <Thead>
            <Tr>
              <Th>Assessment Name</Th>
              <Th>Upload Date</Th>
              <Th>Uploader</Th>
              <Th>Source</Th>
            </Tr>
          </Thead>
          <Tbody>
            {assessments.map((assessment, index) => (
              <Tr key={index}>
                <Td>{assessment.displayName}</Td>
                <Td>{assessment.uploadDate || "N/A"}</Td>
                <Td>{assessment.uploadedBy || "N/A"}</Td>
                <Td>
                  <HStack spacing={2}>
                    <Icon as={getIcon(assessment.sourceDataType)} />
                    <Text>{assessment.sourceDataType || "N/A"}</Text>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Text mb={8}>No assessments available.</Text>
      )}
      <HStack>
        <VStack spacing={4} mb={8} align="start">
          <Heading size="md" mb={4}>
            Upload Files
          </Heading>
          <Box>
            <Input type="file" ref={fileXLSRef} accept=".xls,.xlsx" mb={2} />
            <Button
              onClick={handleUploadSpreadsheet}
              isLoading={fileUploading.xls}
              leftIcon={<AddIcon />}
            >
              Upload Spreadsheet
            </Button>
          </Box>
          <Box>
            <FormControl>
              <FormLabel>Report Type</FormLabel>
              <Select
                value={selectedReportType}
                onChange={(e) => setSelectedReportType(e.target.value)}
                mb={2}
              >
                {reportUploadOptions.map((option, index) => (
                  <option key={index} value={option.uploadMethod}>
                    {option.displayName}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Input type="file" ref={filePDFRef} accept=".pdf" mb={2} />
            <Button
              onClick={handleUploadReportPdf}
              isLoading={fileUploading.pdf}
              leftIcon={<AddIcon />}
            >
              Upload Report PDF
            </Button>
          </Box>
        </VStack>
      </HStack>
      <Heading size="md" mb={4}>
        Draft Reports
      </Heading>
      {draftReports && draftReports.length > 0 ? (
        <List spacing={3}>
          {draftReports.map((report, index) => (
            <ListItem key={index}>
              <Flex align="center">
                <IconButton
                  icon={<DownloadIcon />}
                  onClick={() => handleDownload(report.HreportID, index)}
                  isLoading={loadingIndex === index}
                  aria-label="Download report"
                />
                <Text ml={4}>{report.reportName}</Text>
              </Flex>
            </ListItem>
          ))}
        </List>
      ) : (
        <Text>No draft reports available.</Text>
      )}

      <Heading size="md" mb={4} mt={4}>
        Previous Reports
      </Heading>
      {previousReports && previousReports.length > 0 ? (
        <List spacing={3}>
          {previousReports.map((report, index) => (
            <ListItem key={index}>
              <Flex align="center">
                <IconButton
                  icon={<DownloadIcon />}
                  onClick={() =>
                    handleDownload(report.allocHreportHuserID, index)
                  }
                  isLoading={loadingIndex === index}
                  aria-label="Download report"
                />
                <Text ml={4}>{report.reportName}</Text>
              </Flex>
            </ListItem>
          ))}
        </List>
      ) : (
        <Text>No previous reports available.</Text>
      )}
    </Box>
  );
};

export default Candidate;
